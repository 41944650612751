<template>
  <div id="invoicesAllManage">
    <!-- Table View -->
    <VueDatatable ref="myTable" :setupTable="setupTable">
      <template v-slot:thead>
        <tr>
          <th class="id-col" style="width: 59px">
            <span>ID</span>
          </th>
          <th width="80px">Amount</th>
          <th width="100px">
            <b-form-select
              v-model="search.status"
              :options="statusOptions"
              @change="(v) => dataTable.searching(v, 2)"
              size="sm"
            />
          </th>
          <th>Created</th>
          <th>Order By</th>
          <th>User</th>
        </tr>
      </template>
    </VueDatatable>
  </div>
</template>

<script>
import { BFormSelect } from "bootstrap-vue";
import VueDatatable from "@/components/util/vue-datatable/Index.vue";
import { setupTable, statusOptions } from "@/datatable/invoices.datatable.js";
import Invoice from "@/services/invoice.service.js";

export default {
  name: "OrderHistroy",
  data: () => ({
    search: {
      status: null,
    },
    dataTable: {},
    setupTable,
    statusOptions,
  }),
  async mounted() {
    this.dataTable = this.$refs.myTable;
    this.dataTable.setup({
      url: `/api/invoices/all`,
      forAdmin: true,
      showUser: true,
    });
    window.toPaid = async (invoiceId) => {
      await Invoice.toPaid(invoiceId);
      this.dataTable.refresh();
    };
  },
  components: {
    BFormSelect,
    VueDatatable,
  },
};
</script>

<style>
</style>